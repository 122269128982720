/**
 * List of events used by reviews list widget
 *
 */
export const reviewsList = {
  REVIEWS_LIST_DATA_LOADED: 'reviewsListLoaded',
  REVIEWS_LIST_REVIEW_TRANSLATED: 'reviewsListReviewTranslated',
  REVIEWS_LIST_LANGUAGE_SWITCH: 'reviewsListLanguageSwitch',
  REVIEWS_LIST_PAGINATION: 'reviewsListPagination'
}
