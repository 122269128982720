import * as utilsHelper from '../../../js/utils'
import * as scrollHelper from '../../../js/document/scroll'
import * as loadQueueHelper from '../../../js/document/load-queue'
import domEventsHelper from '../../../js/document/dom-events-helper'
import { getData } from '../../../js/document/html-helper'
import { documentObserver, observerAPI } from '../../../js/document/intersector'

const EventEmitter = require('eventemitter3')

// Default options
// ===============

const componentAPI = 'js-swipe'
const pluginClass = 'js-sundio-swipe'
const selectors = {
  list: 'listClass',
  listItems: 'listItemClass',
  leftArrow: 'leftArrowClass',
  rightArrow: 'rightArrowClass'
}
const defaults = {
  resizeDelay: 250,
  scrollStepPercent: 0.2,
  scrollAnimation: 100,
  observed: true,
  leftArrowClass: 'o-swipe-btn--left',
  rightArrowClass: 'o-swipe-btn--right',
  smooth: true
}

/**
 * Sundio Swipe
 */
export default class Swipe {
  /**
   * Creates a new collapse behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {Element} element - The HTML element.
   * @param {Object} settings - Parameter to set own settings instead of using data-attributes
   */
  constructor (element, settings = {}) {
    this.element = element
    this.events = new EventEmitter()
    this.settings = { ...defaults, ...getData(this.element), ...settings }
    this.selectors = {}

    element[componentAPI] = {
      events: this.events,
      scrollToItem: (item) => this.scrollToItem(item),
      scrollToIndex: (itemIndex) => this._scrollToIndex(itemIndex),
      updateSizes: () => this._updateSizes(),
      remove: () => this.remove(),
      _instance: this
    }

    return this._init()
  }

  // Private functions
  // --------------------------------
  // Initialization
  _init () {
    this
      ._setSelectors()
      ._buildEvents()
    domEventsHelper.attachEvents(this._events, componentAPI)

    if (this.settings.observed && this.selectors.list.length) {
      this._observer = new window.MutationObserver(this._listHasMutated.bind(this))
      this._observer.observe(this.selectors.list[0], {
        attributes: false,
        childList: true,
        subtree: false
      })
    }

    // It is required to schedule the start method to be called again whenever the swipe is made visible
    // because when it's in a tab not visible initially then some widh property is not properly updated
    // (has value 0) and the chevrons are not shown (see bug MF-1662).
    const observer = documentObserver()
    observer.observe(this.element)
    this.element[observerAPI].events.on('enter', () => {
      this._start()
      observer.unobserve(this.element)
    })

    return this._start()
  }

  // Callback when the list had been mutated
  _listHasMutated (mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        this._setSelectors()
        this._start()
      }
    }
  }

  // Set the classes selectors
  _setSelectors () {
    this.selectors = Object.entries(selectors).reduce((selectorsObj, [selectorField, settingField]) => ({
      ...selectorsObj,
      [selectorField]: Array.from(this.element.querySelectorAll('.' + this.settings[settingField]))
    }), {})

    return this
  }

  // Set the classes selectors
  _getItemsWidths () {
    return (this.selectors.listItems.map((element) => element.offsetWidth))
  }

  _updateScrollSizes (newWidth = null) {
    if (!this.selectors.list.length) {
      console.warn('Swipe cannot operate because no list is found', this.element)
      return
    }

    this._width = newWidth || this._getElementWidth()
    this._listTotalScroll = this._getListTotalScroll()

    this._currentScroll = this._getCurrentScroll()
    this._maxScroll = this._getMaxScroll()

    this._scrollStep = this._getScrollStep()
    this._itemsWidths = this._getItemsWidths()
  }

  // Start the plugin logic
  _start (newWidth = null) {
    // this._width = (typeof newWidth !== 'undefined') ? newWidth : this._getElementWidth()
    this._updateScrollSizes(newWidth)

    // If the navigation does not overflow we don't need to handle it
    if (this._listTotalScroll <= this._width) {
      this._updateControls()
      return this
    }

    // Check if one of the items is active and scroll to it
    let activeItem = false
    if (this.selectors.list.length > 0) {
      activeItem = this.selectors.list[0].querySelector('.' + this.settings.activeClass)
    }
    if (activeItem) {
      this.scrollToItem(activeItem) // If there is an active element, scroll to it
    } else { this._updateControls() }

    return this
  }

  // Update the element size after a resize
  _updateSizes () {
    const newWidth = this._getElementWidth()
    if (newWidth === this._width) { return this }

    this.events.emit('resize')

    return this._start(newWidth)
  }

  // Get the width of the main element
  _getElementWidth () {
    return Math.ceil(this.element.getBoundingClientRect().width)
  }

  // Get the total of the list element
  _getListTotalScroll () {
    if (this.selectors.list.length !== 0) {
      return this.selectors.list[0].scrollWidth
    }
  }

  // Get the current scroll of the list element
  _getCurrentScroll () {
    if (this.selectors.list.length !== 0) {
      return this.selectors.list[0].scrollLeft
    }
  }

  // Get the maximum scroll available of the list element
  _getMaxScroll () {
    return (this._listTotalScroll - this._width)
  }

  // Get the scroll step based in the width and the settings
  _getScrollStep () {
    return (this._width * this.settings.scrollStepPercent)
  }

  // Update the state of left/right controls
  _updateControls () {
    this.selectors.leftArrow[0] && this.selectors.leftArrow[0]
      .classList[(this._currentScroll <= 0) ? 'add' : 'remove']('is-disabled')
    this.selectors.rightArrow[0] && this.selectors.rightArrow[0]
      .classList[(this._currentScroll >= this._maxScroll) ? 'add' : 'remove']('is-disabled')
    return this
  }

  _updateControlsDisable (isDisabled = false) {
    this.selectors.leftArrow[0] && this.selectors.leftArrow[0]
      .classList[isDisabled ? 'add' : 'remove']('is-disabled')
    this.selectors.rightArrow[0] && this.selectors.rightArrow[0]
      .classList[isDisabled ? 'add' : 'remove']('is-disabled')
    return this
  }

  // Scroll until a certain point
  _scroll (position) {
    this.settings.smooth ? scrollHelper.smooth(this.selectors.list[0], position, 0) : this.selectors.list[0].scrollTo(position, 0)
    this._updateControls()

    return this
  }

  // List scroll event function
  _scrolledList () {
    this._currentScroll = this._getCurrentScroll()
    this._updateControls()
    this.events.emit('scroll')
  }

  // List click event function
  _listClicked (ev) {
    const element = ev.target
    const itemClicked = element.classList.contains(this.settings.listItemClass)
      ? element
      : element.closest('.' + this.settings.listItemClass)
    if (itemClicked && this.settings.observed) { this.scrollToItem(itemClicked) }
    this.events.emit('click')
  }

  // Left click event function
  _arrowClicked (ev, direction = 'left') {
    ev.preventDefault()
    // Next line fixes the issue of opening the menu on dropdown-multiple component when clicked.
    ev.stopPropagation()
    this._scrollToDirection(direction)
    this.events.emit('scroll ' + direction)
  }

  _scrollToDirection (direction) {
    const multiplier = (direction === 'left') ? -1 : 1
    const newScroll = this._currentScroll + (this._scrollStep * multiplier)
    this._scroll(newScroll)

    return this
  }

  _scrollToIndex (itemIndex) {
    if (this._listTotalScroll !== this._getListTotalScroll()) { this._updateScrollSizes() }
    let scrollPosition = 0

    // Get the width sum until the item
    for (let i = 0; i < itemIndex; i++) { scrollPosition += this._itemsWidths[i] }
    scrollPosition = scrollPosition - ((this._width - this._itemsWidths[itemIndex]) / 2)
    this._scroll(scrollPosition)

    return this
  }

  // Events handle functions
  _buildEvents () {
    this._events = [
      [window, {
        resize: utilsHelper.debounce(() => this._updateSizes(), this.settings.resizeDelay)
      }],
      [this.selectors.list[0], {
        scroll: () => this._scrolledList(),
        click: (ev) => this._listClicked(ev)
      }],
      [this.selectors.leftArrow[0], {
        click: (ev) => this._arrowClicked(ev, 'left')
      }],
      [this.selectors.rightArrow[0], {
        click: (ev) => this._arrowClicked(ev, 'right')
      }]
    ]
    return this
  }

  // Public functions
  // --------------------------------
  /**
   * Add Swipe feature for single element.
   *
   * @param {HTMLElement} element - The targeted HTML element.
   * @param {Object} settings - The instance settings
   *
   * @returns {Swipe} Self instance.
   */
  static CreateInstanceOnElement (element, settings = {}) {
    return ((element[componentAPI] !== undefined) ? element[componentAPI]._instance : new Swipe(element, settings))
  }

  /**
   * Init swipe calling it's constructor over the HTML Elements with a data-api
   * matching the registered component names.
   */
  static CreateInstancesOnDocument (htmlFragment = window.document) {
    const currentComponents = Array.from(htmlFragment.querySelectorAll(`.${pluginClass},[data-${componentAPI}]`))
    const instances = []
    currentComponents.forEach((element) => instances.push(Swipe.CreateInstanceOnElement(element)))
    return instances
  }

  /**
   * Scroll to a specific item.
   *
   * @param {HTMLElement} item - The targeted list item.
   * @returns {Swipe[]} Self instance.
   */
  scrollToItem (item, opc) {
    return this._scrollToIndex(this.selectors.listItems.indexOf(item))
  }

  /**
   * Scroll to a specific position.
   *
   * @param {int} itemIndex - Position of item (indexed from 0).
   * @returns {Swipe[]} Self instance.
   */
  scrollToIndex (itemIndex) {
    return this._scrollToIndex(itemIndex + 1)
  }

  /**
   * Remove Swipe feature from element linked to instance.
   *
   * @returns {void}
   */
  remove () {
    domEventsHelper.detachEvents(this._events, componentAPI)
    if (this._observer) { this._observer.disconnect() }
    this._updateControlsDisable(true)

    this.events.emit('unload')
    delete this.element[componentAPI]
  }
}

// Data Api
loadQueueHelper.add(() => Swipe.CreateInstancesOnDocument())
