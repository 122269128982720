import { rectanglesIntersects } from '../helpers/geometry'

/**
 * elementFromString
 * Returns an HTML element created by a given string
 *
 * @param {String} htmlString
 * @returns {HTMLElement}
 */
export function elementFromString (htmlString) {
  const templateSupport = !!document.createElement('template').content
  const fakeElement = templateSupport ? document.createElement('template') : document.createElement('div')
  fakeElement.innerHTML = htmlString.trim()
  return templateSupport ? fakeElement.content.firstChild : fakeElement.firstChild
}

/**
 * elementFromString
 * Returns an object with the dataset on the element 'el'
 *
 * @param {HTMLElement} el
 * @returns {Object}
 */
export function getData (el) {
  const dataset = {}
  Object.entries(el.dataset).forEach(([field, value]) => value.toLowerCase &&
    (dataset[field] = (value.toLowerCase() === 'true' ? true : (value.toLowerCase() === 'false' ? false : value))))

  return dataset
}

/**
 * getDataPrefixed
 * Returns an object with the dataset on the element 'el'
 * without the field prefix 'prefix'
 *
 * @param {HTMLElement} el
  * @param {String} prefix
 * @returns {Object}
 */
export function getDataPrefixed (el, prefix) {
  const dataset = {}
  Object.entries(el.dataset).forEach(([field, value]) => {
    if (field.indexOf(prefix) >= 0) {
      const newValue = value.toLowerCase() === 'true' ? true : (value.toLowerCase() === 'false' ? false : value)
      let newField = field.replace(prefix, '')
      newField = newField.charAt(0).toLowerCase() + newField.slice(1)
      dataset[newField] = newValue
    }
  })

  return dataset
}

/**
 * flush
 * Empties the current target from content
 *
 * @param {HTMLElement} target
 * @returns {HTMLElement}
 */
export function flush (target) {
  while (target.firstChild) {
    target.removeChild(target.firstChild)
  }
  return target
}

/**
 * moveChildrenFrom
 * Moves children from source element to destination
 *
 * @param {HTMLElement}   source
 * @param {HTMLElement}   destination
 * @param {Object}        options
 * @param {Boolean}       [options.flush]        - If true, destination will be emptied first
 * @param {Boolean}       [options.attributes]  - If true, source attributes will be preserved
 *
 * @returns {HTMLElement}
 */
export function moveChildrenFrom (source, destination, options) {
  options = { flush: false, attributes: false, ...options }
  if (options.flush) { flush(destination) }
  if (options.attributes) {
    Array.from(source.attributes).forEach((a) => {
      if (a.name !== 'class') {
        destination.setAttribute(a.name, a.value)
      } else if (a.value) {
        destination.classList.add(...a.value.trim().split(' '))
      }
    })
  }
  while (source.childNodes.length) { destination.appendChild(source.firstChild) }
  return destination
}

/**
 * isTargetBefore
 * Returns true if target is before the reference element (dom-wide)
 *
 * @param {HTMLElement} reference
 * @param {HTMLElement} target
 * @returns {Boolean}
 */
export function isTargetBefore (reference, target) {
  return (reference.compareDocumentPosition(target) === 0x02)
}

export function isElementInViewport (el) {
  const clientRect = el.getBoundingClientRect()
  const viewportRectangle = {
    x: 0,
    y: 0,
    width: window.innerWidth || document.documentElement.clientWidth,
    height: window.innerHeight || document.documentElement.clientHeight
  }
  const elementRectangle = {
    x: clientRect.left || clientRect.x,
    y: clientRect.top || clientRect.y,
    width: clientRect.width || clientRect.right - clientRect.x,
    height: clientRect.height || clientRect.bottom - clientRect.y
  }

  const instersects = rectanglesIntersects(viewportRectangle, elementRectangle)
  return instersects
}
