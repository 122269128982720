export const flightBusSelector = {
  FLIGHT_SELECTOR_SIDE_DRAWER_CLOSED: 'FlightBusSelector.SideDrawerClosed',
  FLIGHT_SELECTOR_SIDE_DRAWER_OPENED: 'FlightBusSelector.SideDrawerOpened',
  FLIGHT_SELECTOR_COLLAPSE_CLOSED: 'FlightBusSelector.CollapseClosed',
  FLIGHT_SELECTOR_COLLAPSE_OPENED: 'FlightBusSelector.CollapseOpened',
  FLIGHT_SELECTOR_CANCEL: 'FlightBusSelector.Cancel',
  FLIGHT_SELECTOR_SAVE: 'FlightBusSelector.Save',
  FLIGHT_SELECTOR_SELECT_FLIGHT: 'FlightBusSelector.SelectFlight',
  FLIGHT_SELECTOR_SWITCH_TAB: 'FlightBusSelector.SwitchTab'
}
