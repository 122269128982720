/**
 * Just fetches from an URL object JSON data.
 * - Candidate to me moved to a helper.
 *
 * @param {URL}      url                        - The URL from where to fetch the data.
 * @param {Boolean}  fullReferrerOnCrossOrigin  - Flag to force use a referrer policy that ensures that the full URL (not only the origin) is passed when doing cross-origin requests.
 * @param {String}   credentials                - CORS credentials header value to be used. By default it's 'include' so that it is passed in the request.
 *
 * @returns {Promise}
 */
export const fetchJsonData = async (url, { fullReferrerOnCrossOrigin = false, credentials = 'include' } = {}) => {
  return window
    .fetch(url.href, _createFetchOptions(fullReferrerOnCrossOrigin, credentials))
    .then(response => response.json())
}

/**
 * Just fetches from an URL object JSON data if any data is returned and also
 * some context info like HTTP status (code and text) and if the URL was redirected.
 * - Candidate to me moved to a helper.
 *
 * @param {URL}      url                        - The URL from where to fetch the data.
 * @param {Boolean}  fullReferrerOnCrossOrigin  - Flag to force use a referrer policy that ensures that the full URL (not only the origin) is passed when doing cross-origin requests.
 *
 * @returns {Promise}
 */
export const fetchJsonDataAndStatusInfo = async (url, { fullReferrerOnCrossOrigin = false } = {}) => {
  return window
    .fetch(url.href, _createFetchOptions(fullReferrerOnCrossOrigin))
    .then(async response => ({
      ok: response.ok,
      redirected: response.redirected,
      statusCode: response.status,
      // Summary: Gets the text from the header 'x-status-description'.
      // Why this change: "response.statusText" has the standard message related to the status code.
      // Using that property with a custom text (to identify the specific problem) has been totally discarded
      // as it will be deprecated as part of HTTP2, so we (developers & architect) decided  to use
      // a custom response header to send that custom message.
      statusText: response.headers && response.headers.get('x-status-description'),
      jsonData: response.status === 200
        ? await response.json()
        : null
    }))
}

/**
 * Just fetches from an URL object JSON data if any data is returned and also
 * some context info like HTTP status (code and text), the response headers and if the URL was redirected.
 * - Candidate to me moved to a helper.
 *
 * @param {URL}      url                        - The URL from where to fetch the data.
 * @param {Boolean}  fullReferrerOnCrossOrigin  - Flag to force use a referrer policy that ensures that the full URL (not only the origin) is passed when doing cross-origin requests.
 *
 * @returns {Promise}
 */
export const fetchJsonDataAndHeaders = async (url, { fullReferrerOnCrossOrigin = false } = {}) => {
  return window
    .fetch(url.href, _createFetchOptions(fullReferrerOnCrossOrigin))
    .then(async response => ({
      ok: response.ok,
      redirected: response.redirected,
      statusCode: response.status,
      headers: response.headers || new window.fetch.Headers(),
      jsonData: [200, 201, 202].includes(response.status) // Ok, Created, Accepted.
        ? await response.json()
        : null
    }))
}

/**
 * Create the 'window.fetch' method options for a HTTP request.
 * See more info about the full referer parameter:
 * - https://web.dev/referrer-best-practices/
 * - https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Referrer-Policy
 *
 * @param {Boolean}  fullReferrerOnCrossOrigin  - Flag to force use a referrer policy that ensures that the full URL (not only the origin) is passed when doing cross-origin requests.
 * @param {String}   credentials                - CORS credentials header value to be used. By default it's 'include' so that it is passed in the request.
 *
 * @returns {Object}  - Fetch options to be passed to the 'window.fetch' method.
 */
const _createFetchOptions = (fullReferrerOnCrossOrigin, credentials = 'include') => {
  const referrerPolicy = fullReferrerOnCrossOrigin
    ? 'no-referrer-when-downgrade' // Pass the full referrer instead of just the origin in cross-origin requests.
    : undefined // Force to use the default policy, currently most browsers use 'strict-origin-when-cross-origin'.

  return {
    credentials,
    referrerPolicy,
    headers: {
      Accept: 'application/json'
    }
  }
}
