/**
 * The Dropdown Single Option contains all data needed to hydrate a Dropdown Single
 *
 * @typedef {Object}            DropdownData
 *
 * @property {String}           [id]                  Form element id
 * @property {String}           [placeholder]         Form element placeholder
 * @property {Boolean}          [required]            When true selection cannot be empty
 * @property {Boolean}          [disabled]            When true any change cannot be done
 * @property {Boolean}          [isMultiple]          When true allows multiple selection
 * @property {Boolean}          [highlighted]         When true a selection will be distinguished with color
 * @property {Boolean}          [state]
 * @property {Boolean}          [icon]
 * @property {String}           [messageRequired]     Message to be shown on validation when required & empty
 * @property {String}           [track]               Event name to be tracked by DataLayers
 * @property {String}           [extraClasses]        Extra classes to be appended on root element
 * @property {Object}           [attributes]          Extra attributes to be shown
 */
import { encodeAttributes } from '../../../js/helpers/string'
import { getTokenClass } from '../../../js/helpers/tokens'
import { isAnyOptionSelected } from './c-dropdown_helper'
import { DropdownSingleTemplate } from './c-dropdown_single.template'
import { DropdownMultipleTemplate } from './c-dropdown_multiple.template'

// Import tokens
const tokensShared = require('./data/c-dropdown__tokens.json')['c-dropdown']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/dropdown/data/c-dropdown__tokens.json`)['c-dropdown'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultDropdownData = {
  id: '',
  placeholder: '',
  required: false,
  disabled: false,
  isMultiple: false,
  highlighted: false,
  variant: 'default',
  state: false,
  icon: false,
  jsApi: true,
  messageRequired: '',
  hideRequiredSymbol: false,
  track: '',
  extraClasses: '',
  syncSelectedOnLoad: false,
  attributes: {},
  options: []
}

export const DropdownTemplate = (d) => {
  d = { ...defaultDropdownData, ...d }
  return `<div class="c-dropdown${d.isMultiple ? '-multiple' : ''}
      ${d.variant ? getTokenClass('variant', d.variant, tokens) : ''}
      ${d.disabled ? 'is-disabled' : ''}
      ${d.icon ? 'has-icon' : ''}
      ${d.required ? 'is-required' : ''}
      ${d.state ? `has-${d.state}` : ''}
      ${d.highlighted ? 'c-dropdown--highlighted' : ''}
      ${(isAnyOptionSelected(d.options) && ((d.variant !== 'compact' && d.isMultiple) || !d.isMultiple)) ? 'has-value' : ''}
      ${(isAnyOptionSelected(d.options) && (d.variant === 'compact' && d.isMultiple)) ? 'has-values' : ''}
      ${d.hideRequiredSymbol ? 'c-dropdown--without-required-symbol' : ''}
      ${d.extraClasses || ''}"
      ${d.variant ? `data-variant="${d.variant}"` : ''}
      ${d.required ? 'required' : ''}
      ${d.messageRequired && d.messageRequired !== '' ? `data-message-required="${d.messageRequired}"` : ''}
      ${d.track ? `data-track="${d.track}"` : ''}
      ${!d.isMultiple ? `data-c-dropdown__placeholder="${d.placeholder}"` : ''}
      ${d.syncSelectedOnLoad ? 'data-sync-selected-on-load="true"' : ''}
      ${d.jsApi
      ? d.isMultiple
        ? 'data-js-component="c-dropdown-multiple"'
        : 'data-js-component="c-dropdown"'
      : ''}
    ${d.attributes ? encodeAttributes(d.attributes) : ''}>

  ${(d.hiddenLabel && d.variant !== 'compact')
      ? ` <label aria-hidden="true" aria-label="${d.label}" for="${d.id}" data-c-dropdown__label></label>`
      : (d.variant !== 'compact' ? `<label class="c-dropdown__label e-form-label"for="${d.id}" data-c-dropdown__label>${d.label}</label>` : '')
    }

  ${d.isMultiple
      ? DropdownMultipleTemplate(d)
      : DropdownSingleTemplate(d)}
    <div class="c-dropdown__messages">
    </div>
  </div>`
}
